import { AiFillFire, AiFillAlert } from "react-icons/ai";
import { Ri24HoursFill, RiGpsLine } from "react-icons/ri";
import { GiTowTruck, GiBrazil } from "react-icons/gi";
import { FaTruck, FaMoneyBillWaveAlt, FaRedoAlt } from "react-icons/fa";
import { BsFillPersonCheckFill } from "react-icons/bs";

export const Servicos = [
  {
    id: 1,
    icon: <AiFillAlert />,
    title: "Colisão",
    text: "Nossa cobertura contra colisões irá garantir que, no caso de sinistro, você consiga consertar seu veículo no menor tempo possível, em uma das nossas oficinas credenciadas.",
  },
  {
    id: 2,
    icon: <AiFillFire />,
    title: "Incêndio",
    text: "Se o seu veículo pegar fogo e queimar? A Proteauto já inclui proteção contra incêndio em todos os planos",
  },
  {
    id: 3,
    icon: <Ri24HoursFill />,
    title: "Assistências 24h",
    text: "Temos uma central que está 24 hrs a sua disposição, uma das melhores assistências do Brasil, socorro mecânico, chaveiro, borracheiro e muito mais.  ",
  },
  {
    id: 4,
    icon: <GiTowTruck />,
    title: "Guincho 300km",
    text: "Temos 3 tipos de planos para o guincho no caso de pane elétrica ou mecânica, 300km / 600km / 1000km, lembrando que em caso de sinistro não tem esse limite de quilometragem.",
  },
  {
    id: 5,
    icon: <FaMoneyBillWaveAlt />,
    title: "Franquia 3%",
    text: "Cota de participação mais baixa do mercado, apenas 3% do valor do bem  ",
  },
  {
    id: 6,
    icon: <RiGpsLine />,
    title: "Rastreador",
    text: "Se seu veículo estiver acima de 95 mil reais, você ganha um rastreador sem custo mensal para monitorar ele onde estiver, e além disso você terá acesso via login e senha no aplicativo. GRATUITO ACIMA DE 95 MIL",
  },
  {
    id: 7,
    icon: <FaTruck />,
    title: "Terceiro 100 mil",
    text: "Possuímos as opções de cobertura de terceiros de 100 mil / 200 mil / 300 mil / 400 mil / 500 mil reais.",
  },
  // {
  //   id: 8,
  //   icon: <BsPlusCircleFill />,
  //   title: "Cobertura Agregado e Implementos (A Contratar)",
  //   text: "Essa cobertura é exclusiva para veículos de carga, criada para você que tem seu caminhão rodando por todo Brasil e busca por uma cobertura mais completa para não deixar nada de fora, seja um 4° eixo, equipamento de guincho, uma carroceria ou até mesmo uma carreta, poderá contratar mais essa cobertura para deixar seu bruto bem protegido.",
  // },
  {
    id: 9,
    icon: <FaRedoAlt />,
    title: "Destombamento",
    text: "A gente sabe que não podemos contar sempre com a sorte e por vários motivos um veículo pode vir a tombar ou até mesmo capotar, com essa cobertura você garante que se isso acontecer todas as despesas ficam por conta da Proteauto. SERVIÇO DE MUNCK",
  },
  {
    id: 10,
    icon: <BsFillPersonCheckFill />,
    title: "Sem perfil de condutor",
    text: "Nossa proteção é totalmente desburocratizada e não trabalhamos com perfil de condutor, é isso mesmo, você não precisa ficar informando sempre quem é o condutor, desde que o mesmo esteja habilitado para conduzir o veículo. HABILITADO PARA CONDUZIR O VEÍCULO",
  },
  {
    id: 11,
    icon: <GiBrazil />,
    title: "Cobertura nacional 24h",
    text: "Aqui na Proteauto além de um atendimento humanizado, nós te damos uma cobertura completa em todo território nacional sem restrição de horários ou rota, porque sabemos que para o trabalho não existe hora marcada nem rota certa. SEM RESTRIÇÃO DE HORÁRIOS E ROTAS",
  },
];
