import ACCO from "./../../assets/Acco.svg";

export default function Acco() {
  return (
    <div className="w-full self-center justify-center align-middle bg-slate-200 p-2 flex">
      <p className=" ">Desenvolvido por &ensp;</p>
      <a className="self-center" href="https://www.accosistemas.com">
        <img className="w-2/3 " alt="acco sistemas" src={ACCO} />
      </a>
    </div>
  );
}
